














import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component
export default class ReportsGrid extends BaseVue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  subtitle!: string;
}
