



























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiCard from '@/components/ui/UiCard.vue';

import AppLink from '../AppLink.vue';

type Report = { id: string; title: string; subtitle: string; to: string; isBeta?: boolean };

@Component({
  components: {
    UiCard,
    AppLink,
  },
})
export default class ReportsGrid extends BaseVue {
  @Prop({ required: true })
  reports!: Report[];
}
